<template>

  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-edit
          variant="danger"
          class="btn-sm"
          @click="resetModal(true)"
        >
          {{ $t('Add') }}
        </b-button>
      </div>
    </b-col>
    <b-col
      cols="12"
      class="form_custom"
    >
      <b-card-code title="">
        <b-row>
          <b-col cols="6">
            <b-button
              variant="outline-danger"
              class="position-absolute position-bottom-0 btn-sm"
              :disabled="!ids.length"
              @click="deleteMulti"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Delete') }}</span>
            </b-button>
          </b-col>
          <b-col md="3" />
          <b-col md="3">
            <label for="">
              {{ $t("Search") }}
            </label>
            <b-form-input
              v-model="filter.key"
              :placeholder="$t('search_key')"
              @change="loadItems"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <table
              class="table b-table"
              style="margin-top: 10px"
            >
              <thead>
                <tr>
                  <th width="10">
                    <b-form-checkbox
                      v-model="cbxAll"
                      class="custom-control-danger"
                      name="check-button"
                      @change="val => toggleSelectAll(val)"
                    />
                  </th>
                  <th
                    scope="col"
                    width="10"
                  >
                    STT
                  </th>
                  <th
                    scope="col"
                    width="400"
                  >
                    {{ $t("name_q") }}
                  </th>
                  <th
                    scope="col"
                  >{{ $t("description") }}</th>
                  <th
                    width="100"
                    scope="col"
                    :aria-sort="getSortDirection('created_at')"
                    @click="changeSortDirection('created_at')"
                  >
                    {{ $t('crt') }}
                  </th>
                  <th width="50">
                    {{ $t("opiton") }}
                  </th>
                </tr>
              </thead>
              <draggable
                v-model="rows"
                tag="tbody"
                @end="onEnd"
              >
                <template v-if="rows.length">
                  <template v-if="isLoading">
                    <tr
                      v-for="(item) in rows"
                    >
                      <td colspan="1">
                        <b-skeleton animation="fade" />
                      </td>
                      <td colspan="3">
                        <b-skeleton animation="fade" />
                      </td>
                      <td colspan="2">
                        <b-skeleton animation="fade" />
                      </td>
                    </tr>
                  </template>
                  <tr
                    v-for="(item, index) in rows"
                    v-else
                    :id="item.id"
                    :key="item.id"
                  >
                    <td>
                      <b-form-checkbox
                        v-model="ids"
                        :value="item.id"
                        class="custom-control-danger"
                        name="check-button"
                      />
                    </td>
                    <td scope="row">
                      {{ item.order }}
                    </td>
                    <td>
                      <span
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top="item.status? 'Đang hoạt động' : 'Ngưng hoạt động'"
                        variant="outline-primary"
                        style="font-size: 8px"
                        class="mr-1"
                      >
                        <i
                          :class="item.status ? 'text-success' : 'text-danger '"
                          class="fas fa-circle xs"
                        />
                      </span>
                      <span
                        v-b-modal.modal-edit
                        @click="resetModal(false, item)"
                      >
                        {{ item.name }}
                      </span>
                    </td>
                    <td> <span
                      class="text-name"
                      v-html="item.description"
                    /></td>
                    <td>{{ convertDate(item.created_at) }}</td>
                    <td>
                      <b-dropdown
                        id="dropdown-1"
                        class="custom-position-dropdown"
                        variant="flat-sencondary"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle"
                          />
                        </template>
                        <div
                          v-b-modal.modal-edit
                          class="dropdown-item"
                          @click="resetModal(false, item)"
                        >
                          {{ $t('edit') }}
                        </div>
                        <div
                          class="dropdown-item"
                          @click="deleteMulti(item.id)"
                        >{{ $t('Delete') }}</div>
                      </b-dropdown>
                    </td>
                  </tr>
                </template>
                <tr
                  v-else
                >
                  <td colspan="6">
                    <p class="text-center">
                      {{ $t('no_data') }}
                    </p>
                  </td>
                </tr>
              </draggable>
            </table>
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              class="pagination-danger mt-2"
              align="right"
              @input="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  size="18"
                  icon="ChevronLeftIcon"
                />
              </template>
              <template #next-text>
                <feather-icon
                  size="18"
                  icon="ChevronRightIcon"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>

      </b-card-code>
      <b-modal
        id="modal-edit"
        v-model="modalShow"
        :ok-title="$t('save_info')"
        ok-variant="danger"
        :cancel-title="$t('cancel')"
        centered
        size="lg"
        :title="titleAdd"
        @ok.prevent="submit "
      >
        <validation-observer ref="addLevel">
          <b-form class="form_custom">
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('name_q')"
                    rules="required"
                  >
                    <label :class="errors.length > 0 ? 'text-danger': null">
                      {{ $t('name_q') }} <span class="text-danger">(*)</span></label>
                    <b-form-input
                      id="name"
                      v-model="frm.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('description')"
                  >
                    <div>
                      <label>{{ $t('description') }}</label>
                      <b-form-textarea
                        id="description"
                        v-model="frm.description"
                        :placeholder="$t('description')"
                        rows="4"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <div class="form-label-group d-flex">
                  <b-form-checkbox
                    id="status1"
                    v-model="frm.status"
                    class="custom-control-danger"
                    name="is_active"
                    switch
                  />
                  <div>{{ $t('status') }}</div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>

    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'

import {
  BRow,
  BCol,
  BButton,
  VBModal,
  BFormInput,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BListGroup,
  BForm,
  BImg,
  BFormFile,
  BModal,
  BFormRadio,
  BInputGroupAppend,
  BBadge,
  BListGroupItem,
  BFormSelect,
  BFormTextarea,
    BSkeleton,
  BEmbed,
  VBTooltip,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BImg,
    vSelect,
    BSkeleton,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    quillEditor,
    BCol,
    draggable,
    BFormSelect,
    BEmbed,
    BFormFile,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BModal,
    BBadge,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      cbxAll: false,
      modalShow: false,
      currentPage: 1,
      showAddAnswer: false,
      frm: {
        name: null,
        status: false,
        description: '',
      },
      pageLength: 10,
      isShow: true,
      editModal: false,
      dragging: false,
      titleAdd: this.$t('Add'),
      // eslint-disable-next-line global-require
      required,
      isLoading: false,
      total: 0,
      rows: [],
      ids: [],
      modalName: '',
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      filter: {
        key: '',
      },
      sort: {
        by: 'order',
        direction: 'desc',
      },

    }
  },
  watch: {
    ids: {
      handler(value) {
        this.cbxAll = value.length && this._.difference(this.rows.map(v => v.id), value).length === 0
      },
    },
  },
  created() {
    this.loadItems()
  },
  methods: {
    deleteMulti(id) {
      this.$swal({
        title: this.$t('message.Confirm_delete'),
        text: false,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          if (typeof id === 'number') this.ids = [id]
          this.$http.delete('/levels', {
            data: {
              ids: this.ids,
            },
          })
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast(this.$t('message.delete_success'), {
                title: this.$t('message.noitify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
              this.loadItems()
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              console.log('e', e)
              this.$bvToast.toast(this.$t('message.delete_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
              this.ids = []
            })
        }
      })
    },
    toggleSelectAll(value) {
      if (value) {
        this.ids = this.rows.map(v => v.id)
      } else {
        this.ids = []
      }
    },
    onEnd(value) {
      if (value.oldIndex === value.newIndex) return
      const newOrder = this.rows[value.newIndex].order
      const oldOrder = this.rows[value.oldIndex].order
      if (newOrder === oldOrder) return
      if (value.newIndex < value.oldIndex) {
        this.changeOrder(newOrder, this.rows[value.newIndex + 1].order)
      } else {
        this.changeOrder(newOrder, this.rows[value.newIndex - 1].order)
      }
    },
    changeOrder(oldOrder, newOrder) {
      this.isLoading = true
      this.$http.put('/levels', { oldOrder, newOrder })
      // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$bvToast.toast(this.$t('message.update_success'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'success',
            solid: false,
          })
          // eslint-disable-next-line no-unused-vars
        }).catch(e => {
          this.$bvToast.toast(this.$t('message.update_err'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'danger',
            solid: false,
          })
        }).finally(() => {
          this.isLoading = false
          this.loadItems()
        })
    },
    onPageChange(currentPage) {
      this.currentPage = currentPage
      this.loadItems()
    },
    loadItems() {
      this.isLoading = true
      this.$http.get('/levels', {
        params: {
          perPage: this.pageLength,
          page: this.currentPage,
          keyword: this.filter.key,
          order_by: this.sort.by,
          order_direction: this.sort.direction === 'ascending' ? 'asc' : 'desc',
        },
      })
        .then(res => {
          this.total = res.data.total
          this.pageLength = res.data.per_page
          this.rows = res.data.data.map(v => {
            // eslint-disable-next-line no-param-reassign
            v.status = v.status === 1
            return v
          })
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },

    resetModal(flag, level = false) {
      this.titleAdd = flag ? this.$t('Add') : this.$t('Edit')
      // eslint-disable-next-line no-multi-assign
      this.frm.name = this.frm.description = null
      this.editModal = false
      this.frm.status = false

      if (level) {
        this.frm.name = level.name
        this.frm.status = level.status
        this.frm.description = level.description
        this.editModal = level.id
      }
    },
    getSortDirection(sortBy) {
      if (sortBy === this.sort.by) {
        return this.sort.direction
      }

      return 'none'
    },
    changeSortDirection(sortBy) {
      if (this.sort.by !== sortBy) {
        this.sort.direction = 'none'
        this.sort.by = sortBy
      }
      if (this.sort.direction === 'none') {
        this.sort.direction = 'ascending'
      } else if (this.sort.direction === 'ascending') {
        this.sort.direction = 'descending'
      } else {
        this.sort.by = 'order'
        this.sort.direction = 'desc'
      }
      this.loadItems()
    },
    convertDate(iosDate) {
      if (!iosDate) return ''
      const date = new Date(iosDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}/${month}/${year}`
    },
    submit() {
      this.$refs.addLevel.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()

          formData.append('name', this.frm.name)
          if (this.frm.description) formData.append('description', this.frm.description)
          formData.append('status', this.frm.status ? 1 : 0)

          const url = this.editModal ? `/levels/${this.editModal}` : '/levels'
          if (this.editModal) formData.append('_method', 'PUT')

          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.modalShow = false
              this.loadItems()
              this.resetModal()

              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              console.log('error', error.response)
              let errorMsg = {}
              if (error.response.status === 422) {
                errorMsg = error.response.data
              } else {
                errorMsg.name = [error.response.data.error]
              }
              this.$refs.addLevel.setErrors(errorMsg)
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>
